import React, { useState, useEffect } from "react";
import background from "../src/theBackground.png";
import "./App.css";

interface OwnProps {}

interface ReduxStateProps {}

type Props = OwnProps & ReduxStateProps;

// component that displays a button that completes the assessment and provides options based on program type
const Jumbo: React.FC<Props> = (props: Props): JSX.Element => {
  // ternary operator displays either button with dropdown menu for combo assessments or just a button for non combo
  return (
    <>
      {/* <div className="container p-5 text-center bg-image rounded-3" style={{}}>
        <img src={background} width={"100%"} alt={"logo"} />
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="mb-3">Heading</h1>
              <h4 className="mb-3">Subheading</h4>
              <a
                className="btn btn-outline-light btn-lg"
                href="#!"
                role="button"
              >
                Call to action
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="container mask" style={{ height: "100vh" }}>
        <div className="bg"></div>
        <div className="mask2"></div>
        <div className="slogan"></div>
      </div> */}

      <div className="bg"></div>
      <div className="mask"></div>
      <h1 className="slogan">coming soon.</h1>
      {/* <img src={background} width={"100%"} alt={"logo"} /> */}
    </>
  );
};

export default Jumbo;
